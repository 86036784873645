// 申請者側に表示される送信先候補
const ADMIN_SEND_TARGET = {
  SECRETARIAT: 0,
};
const ADMIN_SEND_TARGET_TEXTS = {
  [ADMIN_SEND_TARGET.SECRETARIAT]: '事務局',
};
// メッセージの状態
const MESSAGE_STATUS = {
  UNREAD: 0,
  ALREADYREAD: 1,
  REPLYED: 2,
};
const MESSAGE_STATUS_TEXTS = {
  [MESSAGE_STATUS.UNREAD]: '未読',
  [MESSAGE_STATUS.ALREADYREAD]: '既読',
  [MESSAGE_STATUS.REPLYED]: '返信済',
};
// 件名候補
const MESSAGE_TITLE_LIST = {
  APPLICATIONS: 1,
  CHANGEAPPLICATIONS: 2,
  REQUIRED: 3,
  INTERIM: 4,
  REPORTS: 5,
  JUDGEMENT: 6,
  OTHERS: 99,
};
const MESSAGE_TITLE_TEXTS = {
  [MESSAGE_TITLE_LIST.APPLICATIONS]: '助成申請について',
  [MESSAGE_TITLE_LIST.CHANGEAPPLICATIONS]: '計画変更申請について',
  [MESSAGE_TITLE_LIST.REQUIRED]: '助成手続き書類について',
  [MESSAGE_TITLE_LIST.INTERIM]: '進捗状況報告書について',
  [MESSAGE_TITLE_LIST.REPORTS]: '成果報告書について',
  [MESSAGE_TITLE_LIST.JUDGEMENT]: '審査結果のお知らせ',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};

// 各メール機能も件名固定化
// 登録大学：その他
const UNIVERSITY_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
// 合否設定：審査結果のお知らせ、その他
const JUDGEMENT_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.JUDGEMENT]: '審査結果のお知らせ',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
// 助成申請：助成申請について、計画変更申請について、助成手続き書類について、進捗状況報告書について、成果報告書について、その他
const APPLICATIONS_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.APPLICATIONS]: '助成申請について',
  [MESSAGE_TITLE_LIST.CHANGEAPPLICATIONS]: '計画変更申請について',
  [MESSAGE_TITLE_LIST.REQUIRED]: '助成手続き書類について',
  [MESSAGE_TITLE_LIST.INTERIM]: '進捗状況報告書について',
  [MESSAGE_TITLE_LIST.REPORTS]: '成果報告書について',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
// 助成手続き書類：助成手続き書類について、その他
const REQUIRED_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.REQUIRED]: '助成手続き書類について',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
// 進捗状況報告書：進捗状況報告書について、その他
const INTERIM_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.INTERIM]: '進捗状況報告書について',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
// 成果報告書：成果報告書について、その他
const REPORTS_TITLE_LIST = {
  [MESSAGE_TITLE_LIST.REPORTS]: '成果報告書について',
  [MESSAGE_TITLE_LIST.OTHERS]: 'その他',
};
export default {
  ADMIN_SEND_TARGET_TEXTS,
  MESSAGE_STATUS_TEXTS,
  MESSAGE_TITLE_TEXTS,
  UNIVERSITY_TITLE_LIST,
  JUDGEMENT_TITLE_LIST,
  APPLICATIONS_TITLE_LIST,
  REQUIRED_TITLE_LIST,
  INTERIM_TITLE_LIST,
  REPORTS_TITLE_LIST,
};
