<template>
  <div id="MessageCreate" class="wrap">
    <div class="formWrap">
      <h1 class="title">メッセージ作成</h1>
      <div class="inputBox">
        <b-row class="mt-4 mx-4">
          <div class="label p-0 mb-0 mr-10px">
            <label class="mb-0 bold">送信先</label>
          </div>
          <div class="mb-1 mainArea">
            <b-form-select
              class="h-50px recipient-select"
              v-model="recipientTarget"
              :options="recipientList">
            </b-form-select>
          </div>
        </b-row>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">添付ファイル1</label>
        </div>
        <div class="mb-1 mainArea">
          <b-col class="p-0">
            <b-input-group>
              <span
                v-show="fileList.length === 0"
                class="mb-2 fileName">ファイルが選択されていません</span>
              <span
                v-show="fileList.length > 0"
                class="mb-2 fileName">
                <p
                  v-for="(file, index) in fileList" :key="index"
                  class="mb-0">
                  <b-link @click="downloadApploadedFile(index, file.name)">{{file.name}}</b-link>
                  <img
                    src="@/assets/svg/close_small.svg"
                    @click="deleteFile(index)"
                    class="tdDeleteBtn">
                </p>
              </span>
            </b-input-group>

            <b-input-group>
              <div
              @dragover.prevent="toggleDrag($event, true)"
              @dragleave.prevent="toggleDrag($event, false)"
              @drop.prevent="onDrop($event)"
              :class="[
                isDragging ? 'is-dragging' : '',
                'drop', 'flex', 'flexMiddle', 'flexCenter'
              ]">
                <span>
                  <span
                    :class="[
                      'dragText',
                      'fw-6']">ここにファイルをドラッグ＆ドロップ または</span>
                  <label
                    :for="`file_1`"
                    class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
                  <input type="file" style="display:none;"
                    :id="`file_1`"
                    @change="uplodad($event)"
                  >
                </span>
              </div>
            </b-input-group>

          </b-col>
        </div>
      </b-row>
      <div v-if="errMsg.file_0" class="errorMsg"><span>{{errMsg.file_0[0]}}</span></div>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">添付ファイル2</label>
        </div>
        <div class="mb-1 mainArea">
          <b-col class="p-0">
            <b-input-group>
              <span
                v-show="fileList2.length === 0"
                class="mb-2 fileName">ファイルが選択されていません</span>
              <span
                v-show="fileList2.length > 0"
                class="mb-2 fileName">
                <p
                  v-for="(file, index2) in fileList2" :key="index2"
                  class="mb-0">
                  <b-link @click="downloadApploadedFile2(index2, file.name)">{{file.name}}</b-link>
                  <img
                    src="@/assets/svg/close_small.svg"
                    @click="deleteFile2(index2)"
                    class="tdDeleteBtn">
                </p>
              </span>
            </b-input-group>

            <b-input-group>
              <div
              @dragover.prevent="toggleDrag2($event, true)"
              @dragleave.prevent="toggleDrag2($event, false)"
              @drop.prevent="onDrop2($event)"
              :class="[
                isDragging2 ? 'is-dragging' : '',
                'drop', 'flex', 'flexMiddle', 'flexCenter'
              ]">
                <span>
                  <span
                    :class="[
                      'dragText',
                      'fw-6']">ここにファイルをドラッグ＆ドロップ または</span>
                  <label
                    :for="`file_2`"
                    class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
                  <input type="file" style="display:none;"
                    :id="`file_2`"
                    @change="uplodad2($event)"
                  >
                </span>
              </div>
            </b-input-group>

          </b-col>
        </div>
      </b-row>
      <div v-if="errMsg.file2_0" class="errorMsg"><span>{{errMsg.file2_0[0]}}</span></div>
      <hr/>
      <b-row class="mt-4 mx-4 line">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">件名</label>
        </div>
        <div class="mb-1 mainArea titleSelectWrap">
          <b-form-select
            class="h-50px recipient-select"
            v-model="selectedTitle"
            :options="titleList">
            <template #first>
              <b-form-select-option :value="null">-- 選択してください。 --</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-input
            v-if="selectedTitle === '99'"
            v-model="institution"
            placeholder="例）○○について"/>
        </div>
      </b-row>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">本文</label>
        </div>
        <div class="mb-1 mainArea">
          <b-form-textarea
            v-model="message"
            :rows="10"
            placeholder="入力してください。"/>
        </div>
      </b-row>
      <hr v-show="historyMsgData.length > 0" />
      <b-row v-show="historyMsgData.length > 0" class="mt-4 mx-4">
        <div @click="showLogFlg = !showLogFlg" class="label p-0 mb-0 mr-10px accordion-btn-wrap">
          <label class="mb-0 bold">メッセージ履歴</label>
          <img
            :class="`${showLogFlg ? 'log-show': 'log-close'} filter-btn`" src="@/assets/svg/accordion_btn.svg">
        </div>
        <div v-if="showLogFlg" class="mb-1 mainArea">
          <div v-for="(log, key) in messageLogList" :key="key">
            <div><span class="log-title">送信者：</span>{{log.from_name}}</div>
            <div>
              <span class="log-title">添付：</span>
              <span v-show="log.files_log.length === 0">なし</span>
              <span v-for="(file, file_key) in log.files_log" :key="`file_${file_key}`">
                <span v-if="file_key !== 0">/</span>
                <b-link @click="downloadFile(log.id, file.file_no, file.file_name)">{{file.file_name}}</b-link>
              </span>
            </div>
            <div><span class="log-title">受信日：</span>{{log.send_date}}</div>
            <b-form-textarea
              :value="log.message"
              :rows="10"
              disabled/>
            <hr v-show="messageLogList.length !== (key + 1)" />
          </div>

        </div>
      </b-row>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        v-if="isReplying"
        class="btn back bold"
        @click="$router.push({ name: 'universityMessageInbox' })"
      >
        一覧に戻る
      </b-button>
      <b-button
        v-else
        class="btn back bold"
        @click="$router.push({ name: 'universityApplicationList' })"
      >
        トップに戻る
      </b-button>
      <b-button
        :disabled="!canSend"
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-4"
        @click="sendMessage">メッセージ送信</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CONST_MESSAGE_SETTING from '@/constants/userMsgSetting';
import api from '@/modules/api';
import download from '@/modules/download';

export default {
  name: 'UniMessageCreate',
  components: {

  },
  data() {
    return {
      isDragging: false,
      isDragging2: false,
      selectedTitle: null,
      fileList: [],
      fileList2: [],
      institution: '',
      message: '',
      recipientTarget: 0,
      errMsg: [],
      isReplying: false,
      historyMsgData: [],
      showLogFlg: false,
      tempUpFileList: {},
      upFileKeys: [],
      tempUpFileList2: {},
      upFileKeys2: [],
    };
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { type } = this.$route.meta;
    const { msgId } = this.$route.params;
    this.isReplying = type === 'replying';
    const formData = {
      msgId: null,
    };
    if (type === 'replying') {
      formData.msgId = msgId;
    }
    const response = await api.send('/api/uni/message', formData)
      .catch((err) => {
        console.log(err);
      });
    this.selectedTitle = String(response.data.selectedTitle.title_type);
    if (this.selectedTitle === '99') {
      this.institution = response.data.selectedTitle.title;
    }
    this.historyMsgData = response.data.msgHistory;
    this.$store.dispatch('page/offLoading');
  },
  computed: {
    recipientList() {
      return CONST_MESSAGE_SETTING.ADMIN_SEND_TARGET_TEXTS;
    },
    titleList() {
      return CONST_MESSAGE_SETTING.MESSAGE_TITLE_TEXTS;
    },
    canSend() {
      const hasTitle = this.selectedTitle !== '' && this.selectedTitle !== null;
      const hasMessage = this.message !== '';
      const hasTarget = this.recipientTarget !== '';
      const hasOtherTitle = this.selectedTitle !== '99' || (this.selectedTitle === '99' && this.institution !== '');
      return hasTitle && hasMessage && hasTarget && hasOtherTitle;
    },
    messageLogList() {
      if (this.historyMsgData.length === 0) {
        return [];
      }
      const messageLogList = this.historyMsgData.map((messageLog) => {
        const fromName = `${messageLog.sei || ''} ${messageLog.mei || ''}`;
        const files = (typeof messageLog.files !== 'undefined' && messageLog.files.length > 0) ? messageLog.files.map((file) => {
          return {
            id: file.id,
            file_no: file.file_no,
            file_name: file.file_name,
          };
        }) : [];
        return {
          id: messageLog.id,
          send_date: moment(messageLog.send_date).format('YYYY/MM/DD HH:mm'),
          from_name: fromName,
          files_log: files,
          message: messageLog.text,
        };
      });
      return messageLogList;
    },
  },
  methods: {
    initParams() {
      this.fileList = [];
      this.fileList2 = [];
      this.institution = '';
      this.message = '';
      this.recipientTarget = 0;
      this.errMsg = [];
      this.selectedTitle = null;
    },
    uplodad(event) {
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      this.fileList = [];
      this.fileList.push(file);
      this.tempUpFileList = {};
      let setIndex = 1;
      if (this.upFileKeys.length !== 0) {
        const listMax = Math.max(...this.upFileKeys);
        setIndex = listMax + 1;
      }
      this.upFileKeys.push(setIndex);
    },
    deleteFile(index) {
      const itemNumber = this.upFileKeys[index];
      this.fileList.splice(index, 1);
      this.upFileKeys.splice(index, 1);
      this.$delete(this.tempUpFileList, itemNumber);
      document.getElementById('file_1').value = '';
    },
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return false;
      }
      this.fileList = [];
      this.fileList.push(fileList[0]);
      this.tempUpFileList = {};
      let setIndex = 1;
      if (this.upFileKeys.length !== 0) {
        const listMax = Math.max(...this.upFileKeys);
        setIndex = listMax + 1;
      }
      this.upFileKeys.push(setIndex);
      return true;
    },
    uplodad2(event) {
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      this.fileList2 = [];
      this.fileList2.push(file);
      this.tempUpFileList2 = {};
      let setIndex = 1;
      if (this.upFileKeys2.length !== 0) {
        const listMax = Math.max(...this.upFileKeys2);
        setIndex = listMax + 1;
      }
      this.upFileKeys2.push(setIndex);
    },
    deleteFile2(index) {
      const itemNumber = this.upFileKeys2[index];
      this.fileList2.splice(index, 1);
      this.upFileKeys2.splice(index, 1);
      this.$delete(this.tempUpFileList2, itemNumber);
      document.getElementById('file_2').value = '';
    },
    toggleDrag2(event, isDragging) {
      this.isDragging2 = isDragging;
    },
    onDrop2(event) {
      this.isDragging2 = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return false;
      }
      this.fileList2 = [];
      this.fileList2.push(fileList[0]);
      this.tempUpFileList2 = {};
      let setIndex = 1;
      if (this.upFileKeys2.length !== 0) {
        const listMax = Math.max(...this.upFileKeys2);
        setIndex = listMax + 1;
      }
      this.upFileKeys2.push(setIndex);
      return true;
    },
    async sendMessage() {
      if (!await this.confirm('現在の内容でメッセージを送信してよろしいですか？')) {
        return;
      }
      this.errMsg = [];
      const { type } = this.$route.meta;
      const { msgId } = this.$route.params;
      if (this.selectedTitle !== '' && this.selectedTitle !== null && this.selectedTitle !== '99') {
        this.institution = this.titleList[this.selectedTitle];
      }
      const params = {
        fileCnt: this.fileList.length,
        fileCnt2: this.fileList2.length,
        institution: this.institution,
        message: this.message,
        recipientTarget: this.recipientTarget,
        titleType: this.selectedTitle,
      };

      const formData = {
        form: JSON.stringify(params),
        msgId: null,
      };
      Object.keys(this.fileList).forEach((index) => {
        formData[`file_${index}`] = this.fileList[index];
      });
      Object.keys(this.fileList2).forEach((index) => {
        formData[`file2_${index}`] = this.fileList2[index];
      });
      if (type === 'replying') {
        formData.msgId = msgId;
      }
      this.$store.dispatch('page/onWaiting');
      const response = await api.sendForm('/api/uni/message/send', formData)
        .catch(async (err) => {
          if (err.response.data.message) {
            this.errMsg = err.response.data.message;
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            return false;
          }
          if (err.response.status === 422 && err.response.data.msg) {
            await this.alert(err.response.data.msg, false);
            return false;
          }
          await this.alert('メッセージ送信に失敗しました。', false);
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.initParams();
      await this.alert('メッセージを送信しました。');
      this.$router.push({ name: 'universityMessageInbox' });
    },
    async downloadFile(msgId, fileNo, fileName) {
      const param = {
        msg_id: msgId,
        file_no: fileNo,
        type: this.detailType,
      };
      const url = '/api/uni/message/fileDownload';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadApploadedFile(index, fileName) {
      const fileKey = this.upFileKeys[index];
      const url = '/api/uni/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[fileKey]) {
        const tempUpUrl = '/api/uni/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(fileKey),
        };
        tempParam.file = this.fileList[index];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        this.$set(this.tempUpFileList, fileKey, tempResponse.data.path);
      }
      const path = this.tempUpFileList[fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadApploadedFile2(index, fileName) {
      const fileKey = this.upFileKeys2[index];
      const url = '/api/uni/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList2[fileKey]) {
        const itemNumber = `2-${fileKey}`;
        const tempUpUrl = '/api/uni/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        tempParam.file = this.fileList2[index];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        this.$set(this.tempUpFileList2, fileKey, tempResponse.data.path);
      }
      const path = this.tempUpFileList2[fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
};
</script>
<style scoped>
  #MessageCreate .errorMsg{
    margin-left: 16rem;
    font-size: 12.8px;
    color: #dc3545;
  }
  .title{
    color: #333333;
    font-weight: 300;
    font-size: 24px;
    margin: 0 100px;
    margin-top: 52px;
    border-bottom: solid 2px #A0A9D0;
    padding-left: 10px;
    padding-bottom: 7px;
  }
  .inputBox{
    margin: 0 100px;
    margin-top: 53px;
  }
  .mainArea {
    width: 770px;
  }
  input {
    height: 50px;
  }
  .label {
    width: 230px;
    max-width: 230px;
    height: 25px;
  }
  .line {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .drop {
    height: 100px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }
  .dragText {
    color: #BBB;
  }
  .fileText {
    color: #0A8EA7;
  }
  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }
  .is-dragging {
    color: #6E6E6E;
  }
  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }
  .recipient-select {
    width: auto;
    min-width: 250px;
  }
  .titleSelectWrap {
    display:  flex;
  }
  .titleSelectWrap select {
    margin-right: 25px;
  }
  .log-title{
    min-width: 65px;
    display: inline-block;
  }
  .filter-btn {
    margin-left: 10px
  }
  .filter-btn:hover,
  .accordion-btn-wrap:hover,
  .accordion-btn-wrap label:hover {
    cursor: pointer;
  }
  .log-show {
    transform: rotateX(180deg);
  }
  .log-close {
    transform: rotateX(0deg);
  }
</style>
